<template>
  <div id="home-index">
    <global-nav-header/>
    <div class="content-wrapper">
      <img src="../../images/home/03.png" alt="100% 회사 메일 인증 소개팅앱"/>
      <img src="../../images/home/04.png" alt="사진을 안올리는 블라인드 소개팅"/>
      <img src="../../images/home/05.png" alt="채팅 기능"/>
      <img class="coffee-logo" src="../../images/home/ic_launcher.png" alt="커피한잔 로고"/>

      <h1 class="coffee-title-text">커피한잔</h1>
      <h2 class="coffee-sub-title-text m-3">믿을 수 있는 직장인 블라인드 소개팅</h2>
    </div>

    <p class="text-center mt-5">© 커피한잔</p>

    <footer>
      <download-btn/>
    </footer>
  </div>
</template>

<script>
import GlobalNavHeader from "./global_nav_header.vue";
import DownloadBtn from "@/components/home/download_btn.vue";

export default {
  components: {DownloadBtn, GlobalNavHeader},
}
</script>

<style scoped lang="scss">
@import "../../stylesheets/common";

#home-index {
  .content-wrapper {
    margin: 0 auto;
    max-width: $desktop-max-width;

    text-align: center;

    img {
      max-width: $desktop-max-width;

      @include mobile-page {
        width: 100%;
      }
    }

    .coffee-logo {
      max-width: 250px;
      margin-top: 50px;
    }

    .coffee-title-text {
      margin-top: 30px;
      font-size: 40px;
      font-weight: 900;
      color: $primary;
    }

    .coffee-sub-title-text {
      font-size: 28px;
      font-weight: 100;
      color: #2C2C2F;
    }
  }

  p {
    font-size: 18px;
    margin-bottom: 120px;

    @include mobile-page {
      margin-bottom: 220px;
    }
  }

  footer {
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;

    img {
      max-width: $desktop-max-width;
      border-radius: 5px;

      @include respond-to(mobile) {
        max-width: 100%;
      }
    }
  }
}
</style>