<template>
  <section id="guide-list-item">
    <a class="list-item-wrapper" @click="opened=!opened">
      <div class="title-container">
        <span class="link-wrapper">{{ title }}</span>
      </div>
      <img :src="arrowDownSrc"
           class="notice-more-icon" v-bind:class="{down: !opened, up: opened}"
           alt="가이드 읽어보기">
    </a>

    <div class="card-content" v-if="opened" v-html="formattedContent">
    </div>
  </section>
</template>

<script>
import arrowDown from '../../images/ic_arrow_down.png';

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    }
  },
  methods: {},
  computed: {
    formattedContent() {
      return this.content.replace(/\n/g, "<br>");
    }
  },
  mounted() {
  },
  data() {
    return {
      opened: false,
      arrowDownSrc: arrowDown,
    }
  },
}
</script>
<style scoped lang="scss">
@import '../../stylesheets/application/list_pages';

#guide-list-item {
  .list-item-wrapper {
    text-decoration: none;
    @include list-item();

    .down {
      transition: all ease 0.5s;
    }

    .up {
      transform: rotate(-0.5turn);
      transition: all ease 0.3s;
    }

    img {
      align-self: center;
      width: 24px;
      height: 12px;
    }
  }

  .card-content {
    background-color: #f4f4f4;
    padding: 20px;
    font-size: 14px;
    font-family: "Noto Sans KR", "Malgun Gothic", sans-serif;
    color: #4b525a;
  }
}
</style>