<template>
  <div id="notice-index">
    <global-nav-header :is-logged-in="isLoggedIn"/>
    <div class="flex-container">
      <section v-for="notice in notices" class="notice-item-container">
        <span class="notice-item"><a :href="notice.url">{{ notice.title }}</a></span>
        <span class="notice-date-item">{{ notice.creation_date }}</span>
      </section>
    </div>
  </div>
</template>

<script>
import GlobalNavHeader from "../home/global_nav_header.vue";

export default {
  components: {GlobalNavHeader},
  props: {
    'notices': [],
    'isLoggedIn': Boolean,
  },
  mounted() {
  },
  beforeMount() {
  },
  methods: {},
  data() {
    return {}
  }
}
</script>

<style scoped lang="scss">
@import "../../stylesheets/common";

#notice-index {
  a {
    text-decoration: none;
  }

  .flex-container {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-around;
    margin: 10px auto;
    max-width: 720px;
  }

  .notice-item-container {
    padding: 20px 0;
    display: flex;
    border-bottom: 1px solid #e5e5e5;
    margin: 0 auto;
    max-width: 700px;
    width: 100%;
    @include mobile-page {
      margin: 0 20px;
      width: auto;
    }
  }

  .notice-item {
    flex-shrink: 1;
    font-size: 16px;
    flex-grow: 1;

    a {
      color: $purple;

      &:hover {
        font-weight: bold;
      }

      &:visited {
        color: #666;
      }
    }
  }

  .notice-title {
    padding-top: 20px;
    align-self: center;

    a {
      color: $purple;
      text-decoration: underline;
    }
  }

  .notice-date-item {
    color: #aaaaaa;
    font-size: 14px;
    flex-shrink: 0;
    flex-grow: 0;
    align-self: center;
  }

}
</style>