<template>
  <footer id="coffee-footer">
    <div class="inner_footer">
      <h2 class="screen_out">서비스 이용정보</h2>
      <div class="footer_info">
        <div class="footer-content">
          <a class="link_info" href="/">커피한잔 소개</a>
          <a class="link_info" href="/terms">이용약관</a>
          <a class="link_info txt_emph" href="/privacy">개인정보처리방침</a>
          <a class="link_info" href="/guide">이용가이드</a>
          <a class="link_info" href="/notices">공지사항</a>
          <small class="txt_copyright">
            <div>상호: 커피한잔</div>
            <div>대표자: 김재호</div>
            <div>사업자등록번호: <a href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=4798102353">479-81-02353</a></div>
            <div>통신판매업신고번호: 2021-서울서초-2792</div>
            <div>호스팅 서비스 상호: Amazon Web Services, Inc</div>
            <div>커피한잔 고객센터: <a href="tel:010-5886-9919">010-5886-9919</a></div>
            <div>커피한잔 고객센터: <a href="mailto:withcoffee.me@gmail.com">withcoffee.me@gmail.com</a></div>
            <span class="screen_out">커피한잔 고객센터 안내</span>
            <br>
            Copyright ©
            <a href="/">커피한잔</a>
            All rights reserved.
          </small>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  methods: {},
}
</script>

<style scoped lang="scss">
@import "../../stylesheets/common";

#coffee-footer {
  margin-top: 40px;
  border-top: 1px solid #e8e8e8;
  display: block;
  width: 100%;
  padding: 20px 20px;

  .inner_footer {
    position: relative;
    max-width: $desktop-max-width;
    margin: 0 auto;

    .screen_out {
      overflow: hidden;
      position: absolute;
      width: 0;
      height: 0;
      line-height: 0;
      text-indent: -9999px;
    }

    .footer_info {
      font-size: 14px;

      .link_info {
        display: inline-block;
        margin-right: 10px;
        color: #787878;
      }

      .txt_copyright {
        display: block;
        margin-top: 28px;
        font-size: 13px;
        color: #787878;

        a {
          color: #787878;
          //text-decoration: none;
        }
      }
    }
  }
}
</style>