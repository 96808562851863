<template>
  <div id="download-btn">
    <a @click="clickBtn('ios')" href="https://apps.apple.com/kr/app/id1306780305?ct=coffee-web">
      <span class="coffee-app-store-download-btn"></span>
    </a>
    <a @click="clickBtn('android')" href="https://play.google.com/store/apps/details?id=me.withcoffee.android&pcampaignid=homepage&referrer=utm_source%3Dcoffee-web">
      <span class="coffee-google-play-download-btn"></span>
    </a>
  </div>
</template>

<script>
export default {
  methods: {
    clickBtn(event_label) {
      if (typeof gtag !== 'undefined') {
        gtag('event', 'app_download_click_from_web', {
          'event_label': event_label,
          'event_category': 'behavior_event',
          'source': 'landing',
        });
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import "../../stylesheets/common";

#download-btn {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .coffee-app-store-download-btn {
    margin: 10px;
    display: inline-block;
    background-image: url('../../images/home/app_store.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    max-width: 270px; // Adjust as needed
    max-height: 78px; // Adjust as needed
    width: 100vw;
    height: 100vw;
  }

  .coffee-google-play-download-btn {
    margin: 10px;
    display: inline-block;
    background-image: url('../../images/home/google_play.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    max-width: 270px; // Adjust as needed
    max-height: 78px; // Adjust as needed
    width: 100vw;
    height: 100vw;
  }
}
</style>